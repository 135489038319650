<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>AUDITORIA DE FACTURAS</h5>
                </div>
            </div>

            <div class="card">
                <div class="row"> 
            
                    <div class="card-body custom-datatable">
                        <div class="form-group">
                            <!-- <input type='text' class="filter-ngx form-control" placeholder='Buscar Proveedor...!!!'
                                (keyup)='updateFilteseller($event)' /> -->
                        </div>

                        <ngx-datatable class="bootstrap" [rows]="arrrecive_documents" [columnMode]="'force'"
                            [headerHeight]="50" [footerHeight]="50"  [rowHeight]="'auto'"  [limit]="10" [scrollbarH]="true">

                            <ngx-datatable-column [width]="90" name="Nit" prop="ChrNit"></ngx-datatable-column>
                            <ngx-datatable-column [width]="200" name="Proveedor" prop="chrname_seller">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="80" name="Prefijo" prop="chprefix"></ngx-datatable-column>
                            <ngx-datatable-column [width]="80" name="Numero" prop="chrnumber"></ngx-datatable-column>

                            <ngx-datatable-column [width]="100" name="Fecha" prop="date_issue">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{row.date_issue | date: 'mediumDate'}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="250" name="XML" prop="chrnamexml"></ngx-datatable-column>

                            <ngx-datatable-column [width]="150" name="Total" prop="dbltotal">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{row.dbltotal | currency: 'COP'}}
                                </ng-template>
                            </ngx-datatable-column>

                        </ngx-datatable>

                    </div>
                </div> 
            </div>

        </div>

        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>
        </div>


    </div>
</div>
<!-- Container-fluid Ends -->