import { Component, OnInit } from '@angular/core';
import { RptAuditoria, RptAuditoriaRoot } from 'src/app/shared/models/reports';

@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.scss']
})
export class AuditoriaComponent implements OnInit {

  arrrecive_documents: RptAuditoria[];
  temp: RptAuditoria[];
  Cargando: string;

  constructor() { 
    this.Cargando = 'none';
  }

  ngOnInit(): void {
  }

  async cargar_auditoria_documents(): Promise<void> {
  //   try {
  //     this.Cargando = 'Block';

  //   //   const res: GetReceivedDocumentRoot = await this.inoviceService.get_received_documents(
  //   //     {
  //   //       IdEmpresa: this.IdEmpresa,
  //   //       IdSucursal: this.IdSucursal,
  //   //     })

  //   //   this.temp = [...res.result]
  //   //   this.arrrecive_documents = res.result;

  //   //   this.Cargando = 'none';

  //   // } catch (error) {
  //   //   Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
  //   // }
  // }

  }

}
