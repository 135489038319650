import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ListarFacturasGenerada, ListarFacturasGeneradaRoot } from 'src/app/shared/models/invoice';
import { InvoiceService } from '../../../shared/services/invoice.service'
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

@Component({
  selector: 'app-listafactura',
  templateUrl: './listafactura.component.html',
  styleUrls: ['./listafactura.component.scss']
})
export class ListafacturaComponent implements OnInit {

  IdEmpresa: string;
  IdSucursal: string;
  Cargando: string;
 
  arrFacGeneradas: ListarFacturasGenerada[];
  temp: ListarFacturasGenerada[];

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(
    private inoviceService: InvoiceService,
  ) {
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    this.Cargando = 'none';
  }

  async ngOnInit() {
    this.cargar_facturas()
  }


  updateFiltercliente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.ChrNombreCompleto.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrFacGeneradas = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  updateFilternumero(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.IntIdFactura.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrFacGeneradas = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  async cargar_facturas() {
    try {
      this.Cargando = 'Block';

      const res: ListarFacturasGeneradaRoot = await this.inoviceService.getAllFactGeneradas(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          MostarDatos: 1000
        })

        console.log(res)
      this.temp = [...res.result]
      this.arrFacGeneradas = res.result;

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + error + '</p></div>', icon: 'error'
      });
    }
  }

}
