import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CookieService } from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CsvModule } from '@ctrl/ngx-csv';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FileUploadModule } from 'ng2-file-upload';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from "./shared/shared.module";
import { LoginComponent } from './components/General/login/login.component';
import { ListarempresasComponent } from './components/General/listar-empresas/listar-empresas.component';
import { TokenInterceptorService } from '../app/shared/services/tokeninterceptor.service';


import { ModalModule } from 'ngx-bootstrap/modal';
import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { TercerosComponent } from './components/terceros/terceros/terceros.component';
import { RecibodocumentosComponent } from './components/recibodocumentos/recibodocumentos.component';
import { InventariosComponent } from './components/inventarios/inventarios.component';
import { CarteraComponent } from './components/cartera/cartera_general/cartera.component';
import { GarantiasComponent } from './components/facturas/garantias/garantias.component';
import { CarteraVendedorComponent } from './components/cartera/cartera-vendedor/cartera-vendedor.component';
import { ListafacturaComponent } from './components/Facturas/listafactura/listafactura.component';
import { AuditoriaComponent } from './components/Reports/auditoria/auditoria.component';
registerLocaleData(localeES, 'es')


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ListarempresasComponent,
    TercerosComponent,
    RecibodocumentosComponent,
    InventariosComponent,
    CarteraComponent,
    GarantiasComponent,
    CarteraVendedorComponent,
    ListafacturaComponent,
    AuditoriaComponent
  ],
  imports: [
    BrowserModule,
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: 'No hay resultado para mostrar..!!', // Message to show when array is presented, but contains no values
        totalMessage: 'total', // Footer total message
        selectedMessage: 'selected' // Footer selected message
      }
    }),
    AppRoutingModule,
    AutocompleteLibModule,
    SharedModule,
    FormsModule,
    NgbModule,
    FileUploadModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    CsvModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    CookieService,
    {
      provide: LOCALE_ID, useValue: 'es'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
